:root {
  --primary: #FFF;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--secondary{
  background-color: #000;
  border-top: 2px solid #4ACAB1;
  border-left: 2px solid #4ACAB1;
  color: #4ACAB1;
  border-radius: 10px;
}

.btn--secondary:hover{
  background-color: #000;
  border-top: 2px solid #FBE35E;
  border-left: 2px solid #FBE35E;
  color: #FBE35E;
  border-radius: 10px;
}

.btn--primary{
  background-color: #000;
  border-top: 2px solid #4ACAB1;
  border-left: 2px solid #4ACAB1;
  color: #4ACAB1;
  border-radius: 10px;
}

.btn--primary:hover{
  background-color: #000;
  border-top: 2px solid #FBE35E;
  border-left: 2px solid #FBE35E;
  color: #FBE35E;
  border-radius: 10px;
}


.btn--outline {
  background-color: #000;
  color: #FFF;
  padding: 8px 20px;
  border: 2px solid #000;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}

.btn--outline:hover {
  border-top: 2px solid #4ACAB1;
  border-left: 2px solid #4ACAB1;
  color: #ffffff;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover {
  background: #000;
  color: #FFF;
  transition: all 0.3s ease-out;
}

.dot {
  color: #4ACAB1;
}
