.PortfolioSection {
  background: #000000;
  color: #ffffff;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.portfolioImage {
  width: 100%;
  height: auto;
  border-radius: 16px;
  min-height: 450px;
  max-height: 450px;
  object-fit: cover !important;
  transition: 1s;
}

.portfolioImage:hover {
  border-radius: 0px 100px 200px 200px;
  transition: 1s;
}

.Projects {
  background: #000;
  transition: all 0.5s linear;
  padding: 40px;
  margin-bottom: 0rem;
  border-bottom: 1px solid #ccc;
  margin-right: 40px;
}

.Projects:hover {
  background: #fbe35e;
  transition: all 0.5s linear;
}

.PortfolioSection a {
  text-decoration: none;
}

.PortfolioSection a:hover {
  text-decoration: none;
}

.Projects .caption{
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
}

.Projects .arrows {
  text-align: right;
  float: right;
  color: #ffffff;
  font-size: 50px;
  margin-top: 0rem;
  text-decoration: none;
  position: absolute;
  right: 40px;
}


.PortfolioSection hr {
    opacity: 0.3;
    color: #f4f4f4;
}

.PortfolioSection h1 {
  color: #ffffff;
  font-size: 40px;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.PortfolioSection span{
  color: #ffffff;
  font-size: 20px;
  text-align: left;
  text-decoration: none;
}

.PortfolioSection h2 {
  color: #ffffff;
  font-size: 40px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 200;
  text-decoration: none;
}

.PortfolioSection p {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}

.PortfolioSection h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}

/* Parallax effects STARTS */
.parallaxPortfolio {
  /* The image used */
  background-image: url("./assets/images/iwebdesignservice.jpg");

  /* Set a specific height */
  min-height: 400px;
  max-height: 100%;
  width: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
}
/* Parallax effects END */

@media screen and (max-width: 960px) {

.parallaxPortfolio {
  display: none;
}

.Projects {
  margin: 20px;
  padding: 10px;
}

.Projects .caption{
    font-size: 14px;
    color: #FFFFFF;
}

.Projects .arrows {
  text-align: right;
  float: right;
  color: #ffffff;
  font-size: 36px;
  margin-top: 0rem;
}


.parallax {
  min-height: 200px;
}

.PortfolioSection
  {
    padding: 80px;
  }

.PortfolioSection h2 {
  font-size: 26px;
}

.PortfolioSection h1 {
    font-size: 36px;
    line-height: normal;
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {

.portfolioImage {
  max-height: auto;
  object-fit: contain;
  min-height: 170px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.PortfolioSection h1 {
    font-size: 36px;
    line-height: normal;
    padding: 0px;
  }

.PortfolioSection p {
    font-size: 16px;
    text-align: left;
    width: 100%;
    padding: 0px;
  }

.btn{
  text-align: left;
}

.btn--outline {
    border-top: 2px solid #4ACAB1;
    border-left: 2px solid #4ACAB1;
    color: #ffffff;
}


}
