.PortfolioCard {
  background: #000000;
  color: #ffffff;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}


.PortfolioCard h2 {
  color: #ffffff;
  font-size: 40px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 200;
  text-decoration: none;
}

.PortfolioCard p {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}






.contenedor {
  height: 100%;
  padding: 0% 0;
}

.container_foto {
  background-color: rgba(57, 62, 93, 0.7);
  padding: 0;
  overflow: hidden;
  max-width: calc(90% / 2);
  width: calc(90% / 2);
  height: 330px;
  min-height: 330px;
  margin: 20px;
  border-radius: 16px;
  border: 1px solid #000;
}

.container_foto article {
  padding: 10%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.container_foto h2 {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  line-height: 26px;
  border-bottom: #fff solid 1px;
}

.container_foto h4 {
  font-weight: 300;
  color: #fff;
  line-height: 26px;
  font-size: 16px;
}

.container_foto img {
  width: 100%;
  min-height: 330px;
  height: 330px;
  top: 0;
  left: 0;
  opacity: 0.4;
  -webkit-transition: all 4s ease;
  -moz-transition: all 4s ease;
  -o-transition: all 4s ease;
  -ms-transition: all 4s ease;
  transition: all 4s ease;
  object-fit: fill;
}

.ver_mas {
  background-color: #4ACAB1;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transform: translate(0px, 70px);
  -webkit-transform: translate(0px, 70px);
  -moz-transform: translate(0px, 70px);
  -o-transform: translate(0px, 70px);
  -ms-transform: translate(0px, 70px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.ver_mas span {
  font-size: 40px;
  color: #fff;
  position: relative;
  margin: 0 auto;
  line-height: 26px;
  width: 100%;
  top: 13px;
}


/*hovers*/

.container_foto:hover {
  cursor: pointer;
}

.container_foto:hover img {
  opacity: 0.1;
  transform: scale(1.5);
}

.container_foto:hover article {
  transform: translate(2px, -69px);
  -webkit-transform: translate(2px, -69px);
  -moz-transform: translate(2px, -69px);
  -o-transform: translate(2px, -69px);
  -ms-transform: translate(2px, -69px);
}

.container_foto:hover .ver_mas {
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  opacity: 1;
}


@media screen and (max-width: 960px) {

.PortfolioCard
  {
    padding: 80px;
  }

.PortfolioCard h2 {
  font-size: 26px;
  }

.container_foto {
    background-color: rgba(57, 62, 93, 0.7);
    padding: 0;
    overflow: hidden;
    max-width: calc(100% / 1);
    width: calc(100% / 1);
    margin: 20px;
    border-radius: 16px;
  }


}

@media screen and (max-width: 768px) {

.PortfolioCard p {
    font-size: 16px;
    text-align: left;
    width: 100%;
    padding: 20px;
  }
}
