.Areas-of-expertise {
  background: #FFFFFF;
  color: #000000;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.Areas-of-expertise h1 {
  color: #000000;
  font-size: 40px;
  text-align: left;
}

.Areas-of-expertise h2 {
  color: #000000;
  font-size: 20px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 300;
}

.Areas-of-expertise p {
  color: #000000;
  text-align: left;
  font-weight: 200;
  font-size: 14px;
}

.Areas-of-expertise h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #00000);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}


.card {
  transition: all 0.5s linear;
  border-radius: 16px;
  margin: 1rem;
  margin-top: 2rem;
  padding: 15px;
  box-shadow: 0px 0px #FBE35E;
  border-bottom: 4px solid #FBE35E;
  border-right: 4px solid #FBE35E;
  border-top: 4px solid #FFFFFF;
  border-left: 4px solid #FFFFFF;
}

.card:hover {
  transition: all 0.5s linear;
  border-radius: 16px;
  border-top: 4px solid #4ACAB1;
  border-left: 4px solid #4ACAB1;
  border-bottom: 4px solid #FFFFFF;
  border-right: 4px solid #FFFFFF;
}

.card-title {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
}

.webdesignicons {
  width: 100%;
  height: 100%;
  max-width: 65px;
  min-width: 65px;
  min-height: 60px;
  max-height: 60px;
  text-align: left;
}

.websitedevelopment {
  width: 100%;
  height: 100%;
  max-width: 100px;
  min-width: 100px;
  min-height: 75px;
  max-height: 75px;
  text-align: left;
  object-fit: cover;
}

.borderBtn {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px;
  transition: .3s all ease;
  color: #000;
}

.borderBtn:hover {
  border: 1px solid #4acab1;
  border-radius: 6px;
  padding: 10px;
  color: #4acab1;
}

@media screen and (max-width: 960px) {
.Areas-of-expertise h1 {
    font-size: 30px;
    line-height: normal;
  }
.Areas-of-expertise {
    padding: 20px;
    padding-top:0px;
}
.card {
  margin: 0rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

}

@media screen and (max-width: 768px) {
.Areas-of-expertise h1 {
    font-size: 30px;
    width: 100%;
    line-height: normal;
  }

.Areas-of-expertise p {
    font-size: 14px;
    text-align: left;
    width: 100%;
  }
}
