.footer {
  background: #000000;
  color: #FFFFFF;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 66px;
  padding-bottom: 0px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.inner-footer {
  background: #151515;
  border-radius: 16px;
  padding: 40px;
  width: 100%;
}

.inner-footer .large {
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
}

.inner-footer p {
  font-size: 12px;
  color: #FFFFFF;
  opacity: 0.7;
  margin-top: 1rem;
  letter-spacing: 0.5px;

}

.copyright {
    opacity: 0.7;
    font-size: 12px;
}

.footer .iWebdesign {
  width: 100px;
  height: 100px;
}

.footer .companyname {
  opacity: 0.9px;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-size: 12px;
  color: #FBE35E;
}

.footer .copyright {
  margin-top: 3rem;
}

.footer .caption {
    padding: 20px;
    padding-bottom: 0px;
}

.footer a {
  color: #FFFFFF;
  opacity: 0.7px;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-size: 12px;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #FBE35E, #FBE35E 50%, #ffffff 50%);
  background-size: 200% 100%;
  background-position: 100%;
}

.footer a:hover {
  color: #FBE35E;
  opacity: 0.7px;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 0.5px;
  transition: all 1.1s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}

.partners {
  width: 150px;
  margin-top: 2rem;
  border-radius: 14px;
}

@media screen and (max-width: 960px) {

  .footer {
    background: #000000;
    color: #FFFFFF;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .inner-footer {
    padding: 20px;
  }

  .footer .large {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
    padding-top: 2rem;
  }

  .footer .copyright {
      marign-top: 3rem;
  }

}
