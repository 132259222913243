video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('assets/img-2.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.7);
  object-fit: contain;
  margin-top: -2rem;
}

.hero-container > h1 {
  color: #FFFFFF;
  font-size: 46px;
  margin-top: 0px;
  width: 100%;
  max-width: 80%;
  text-align: center;
  letter-spacing: 1.5px;
}

h1::selection {
  background-color: #f1c40f !important;
  color: #FFFFFF;
}

.hero-container > p {
  margin-top: 20px;
  color: #FFFFFF;
  font-size: 18px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.hero-btns{
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {

  .hero-container > h1 {
    font-size: 30px;
    margin-top: 0px;
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {

  video {
    display: none;
  }

  .hero-container > h1 {
    font-size: 30px;
    margin-top: 0px;
    text-align: left;
  }

  .hero-container > p {
    font-size: 16px;
    width: 100%;
    max-width:90%;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
