.BlogSection {
  background: #FFFFFF;
  color: #000000;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.BlogSection h1 {
  color: #000000;
  font-size: 40px;
  text-align: left;
}

.BlogSection h2 {
  color: #000000;
  font-size: 20px;
  text-align: left;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-weight: 300;
}

.BlogSection p {
  color: #000000;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  width: 100%;
}

.Blog {
  background: #fbe35e;
  border: 2px solid #fbe35e;
  border-radius: 16px;
  transition: all 0.5s linear;
  padding: 30px;
  color: #000000;
  margin-bottom: 0rem;
  border-bottom: 1px solid #ccc;
  margin-right: 40px;
  margin-top: 2rem;
}

.Blog:hover {
  background: #FFFFFF;
  border: 2px solid #fbe35e;
  color: #000000;
  padding: 40px;
  border-radius: 16px;
  transition: all 0.5s linear;
}

.Blog .caption{
    font-size: 14px;
    color: #000000;
    text-decoration: none;
}

.Newarrows {
  text-align: center;
  float: right;
  color: #4ACAB1;
  font-size: 60px;
  margin-top: -1rem;
  text-decoration: none;
  -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

.BlogSection a {
  text-decoration: none;
}

.blogicons {
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  text-align: left;
}

.blogimage {
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: left;
  border-radius: 14px;
  transition: transform .7s ease-in-out;
}
.blogimage:hover { transform: rotate(360deg); }


@media screen and (max-width: 960px) {
.BlogSection h1 {
    font-size: 30px;
    line-height: normal;
  }
.BlogSection {
    padding: 20px;
    padding-top:0px;
}

}

@media screen and (max-width: 768px) {
.BlogSection h1 {
    font-size: 30px;
    width: 100%;
    line-height: normal;
  }

.Blog {
  margin-right: 5px;
}

.Newarrows {
  transform: rotate(90deg) !important;
}

.BlogSection p {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
}
