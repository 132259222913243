.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: rgba(0,0,0,0.9);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 0px solid #EEEEEE;
  box-shadow: 0px 1px #000000;
  border-radius: 0px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  margin-top: 0.3rem;
}

.navbar-logo {
  color: #FFFFFF;
  justify-self: start;
  margin-left: 1px;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 1.3px;
  display: flex;
  font-weight: 100;
  align-items: center;
  transition: .5s ease;
  /*-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #FBE35E, #FBE35E 50%, #ffffff 50%);
  background-size: 200% 100%;
  background-position: 100%;*/
}

.navbar-logo:hover {
  transition: all 1.1s cubic-bezier(0, 0, 0.23, 1);
  color: #FBE35E;
  background-position: 0%;
  text-decoration: none;
}

.web {
  font-size: 20px;
  color:#FFFFFF;
  font-weight: 200;
  text-decoration: none;
  margin-bottom: 0;
}

.design {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 1.3px;
  color:#FFFFFF;
}

.companyname{
  font-size: 20px;
  color:#FBE35E;
  font-weight: 200;
  text-decoration: none;
}

.navbar a {
  text-decoration: none;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.4rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 67vw;
  height: 80px;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 0rem;
}

.nav-item {
  height: 90px;
}

.nav-links {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 15px;
  letter-spacing: 1.1px;
  transition: .5s ease;
  /*-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #FBE35E, #FBE35E 50%, #ffffff 50%);
  background-size: 200% 100%;
  background-position: 100%;
  */
}


.nav-links:hover {
  transition: all 1.1s cubic-bezier(0, 0, 0.23, 1);
  color: #FBE35E;
  background-position: 0%;
  text-decoration: none;
}

.nav-menu.active {
  color: #FBE35E;
}

.fa-bars {
  color: #ffffff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {


  .navbar {
    height: 75px !important;
    margin-bottom: 0;
  }
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-top: 0rem;
    justify-content: start;
  }


  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2;
    height: 200vw;
    background: #000;
  }

  .nav-item {
    height: 40px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    background-color: #000;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 8px;
    left: 0;
    color: #FFFFFF;

    -webkit-background-clip: none !important;
    -webkit-text-fill-color: none !important;
    background-image: none !important;
    background-size: none !important;
    background-position: none !important;
  }

  .companyname {
    color: #FBE35E;
    position: absolute;
    left: 24px;
    top: 23px;
}

.web {
  font-size: 20px;
  color:#FFFFFF;
  font-weight: 200;
  text-decoration: none;
  margin-bottom: 0;

  position: absolute;
  left: 31px;
  top: 23px;
}

.design {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 1.3px;
  color:#FFFFFF;
  position: absolute;
  left: 77px;
  top: 23px;
}

  .menu-icon {
    display: block;
    position: absolute;
    top: 9px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
