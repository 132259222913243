.PortfolioSection {
  background: #000000;
  color: #ffffff;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.Projects {
  background: #000;
  border-radius: 16px;
  transition: all 0.5s linear;
  padding: 40px;
  color: #FFFFFF;
  margin-bottom: 0rem;
  border-bottom: 1px solid #ccc;
  margin-right: 40px;
}

.Projects:hover {
  background: #fbe35e;
  color: #000000;
  border-radius: 16px;
  transition: all 0.5s linear;
}

.PortfolioSection a {
  text-decoration: none;
}

.PortfolioSection a:hover {
  text-decoration: none;
}

.Projects .caption{
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
}

.Projects .arrows {
  text-align: right;
  float: right;
  color: #ffffff;
  font-size: 60px;
  margin-top: 0rem;
  text-decoration: none;
}


.PortfolioSection hr {
    opacity: 0.3;
    color: #f4f4f4;
}

.PortfolioSection h1 {
  color: #ffffff;
  font-size: 40px;
  text-align: left;
  text-decoration: none;
}

.PortfolioSection span{
  color: #ffffff;
  font-size: 20px;
  text-align: left;
  text-decoration: none;
}

.PortfolioSection h2 {
  font-size: 40px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 200;
  text-decoration: none;
}

.PortfolioSection p {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}

.PortfolioSection h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #000000, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

/* Parallax effects STARTS */
.parallax {
  /* The image used */
  background-image: url("./assets/images/iwebdesignservice.jpeg");

  /* Set a specific height */
  min-height: 550px;
  max-height: 100%;
  width: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
}
/* Parallax effects END */

@media screen and (max-width: 960px) {

.Projects {
  margin: 20px;
  padding: 30px;
}

.Projects .caption{
    font-size: 14px;
    color: #FFFFFF;
}

.Projects .arrows {
  text-align: right;
  float: right;
  color: #ffffff;
  font-size: 36px;
  margin-top: 0rem;
}


.parallax {
  min-height: 200px;
}

.PortfolioSection
  {
    padding: 20px !important;
  }

.PortfolioSection h2 {
  font-size: 26px;
}

.PortfolioSection h1 {
    font-size: 36px;
    line-height: normal;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {


.PortfolioSection h1 {
    font-size: 36px;
    line-height: normal;
    padding: 20px;
  }

.PortfolioSection p {
    font-size: 16px;
    text-align: left;
    width: 100%;
    padding: 20px;
  }
}
