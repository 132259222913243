.Building-your-success-online {
  background: #FFFFFF;
  color: #000000;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.Building-your-success-online h1 {
  color: #000000;
  font-size: 40px;
  text-align: left;
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.Building-your-success-online h2 {
  color: #000000;
  font-size: 20px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 300;
}

.Building-your-success-online p {
  color: #000000;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}

.Building-your-success-online h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #000000, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

/* Parallax effects STARTS */
.parallax {
  /* The image used */
  background-image: url("./assets/images/iwebdesignservice.jpeg");

  /* Set a specific height */
  min-height: 550px;
  max-height: 100%;
  width: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
}
/* Parallax effects END */

.borderBtn {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px;
  transition: .3s all ease;
  color: #000;
}

.borderBtn:hover {
  border: 1px solid #4acab1;
  border-radius: 6px;
  padding: 10px;
  color: #4acab1;
}


@media screen and (max-width: 960px) {
.Building-your-success-online
  {
    padding: 20px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

.Building-your-success-online h1 {
    font-size: 30px;
    line-height: normal;
  }
}

@media screen and (max-width: 768px) {
.Building-your-success-online h1 {
    font-size: 30px;
    line-height: normal;
  }

.Building-your-success-online p {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
}
