.calltoaction {
  background: #000000;
  color: #ffffff;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 80px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.calltoaction h6 {
  color: #ffffff;
  font-size: 80px;
  text-align: left;
  width: 100%;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.calltoaction h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  width: 100%;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #000000, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Downarrows{
  text-align: center;
  float: left;
  color: #4ACAB1;
  font-size: 60px;
  margin-top: -1rem;
  text-decoration: none;
  transform: rotate(90deg);

  -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
.ScrollDownText {
  font-size: 12px;
  color: #FFF;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

/* Parallax effects STARTS */
.parallaxCalltoAction {
  /* The image used */
  background-color: #000;
  /* background-image: url("./assets/images/getintouch.jpeg"); */
  /* Set a specific height */
  min-height: 400px;
  width: 100%;
  height: 100%;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  padding: 20px;
}
/* Parallax effects END */


@media screen and (max-width: 960px) {

.parallaxCalltoAction {
  min-height: 200px;
}

.calltoaction
  {
    padding: 20px;
  }

.calltoaction h1 {
    font-size: 30px;
    line-height: normal;
  }
.downArrowtalk {
  margin-bottom: 5rem;
}
}

@media screen and (max-width: 768px) {
.calltoaction h2 {
    font-size: 36px;
    line-height: normal;
  }

.calltoaction p {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
}
