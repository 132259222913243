* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.1px;
}

body {background-color: #000; overflow-x: hidden;}

a:focus {
    color: #fbe35e !important;
}

h1,h2,h3,h4,h5 {line-height: 1.3;}

h1::selection, h2::selection, h3::selection, h4::selection, h5::selection {
  background-color: #f1c40f;
  color: #FFFFFF;
}
.errorPage {
  color: #FFFFFF;
  margin-top: 3rem;
}

.ReturnHome {
  color: #FFF;
}

p::selection {
background-color: #4ACAB1;
color: #FFFFFF;
}

span::selection {
background-color: #4ACAB1;
color: #FFFFFF;
}

a { text-decoration: none; color:#000;}
a:hover { text-decoration: none; }

.page-progress {
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 6px;
    background-color: #f1c40f;
    color: #f1c40f;
    -webkit-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
}

.Areas-of-expertise a { text-decoration: none; color: #000000;}

.cms-icons{
  width: 100%;
  height: 100%;
  max-width: 85px !important;
  min-width: 85px !important;
  min-height: 70px !important;
  max-height: 70px !important;
  object-fit: contain !important;
}

.home,
.services,
.portfolio,
.OurStory,
.Designtrends {
  display: flex;
  height: 30vh;
  padding-left: 80px;
  padding-top: 80px;
  padding-bottom: 0px;
  padding-right: 0px;
  align-items: left;
  justify-content: left;
  font-size: 3rem;
  color: #FFF;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.pageTitle {
  border-top: 0px solid #FFF;
  width: 100%;
  margin-top: 0.1rem;
}

.storyIntroduction {
  width: 100%;
  background: #fff;
  color:#000;
  padding:40px;
}



/** START: Animation effects on Introduction Texts **/
.cssanimation, .cssanimation span {
    animation-duration: 2s;
    animation-fill-mode: both;
}

.cssanimation span { display: inline-block }

.fadeInBottom { animation-name: fadeInBottom }

.animateImage {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  transition: transform .7s ease-in-out;
}

.bullets {margin-left: 1rem; list-style: disclosure-closed;}

.animateImage:hover { transform: rotate(360deg); }

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}

/** END : Animation effects on Introduction Texts **/



/**  [ iWebdesign Auckland Services Section ]  **/

.iWebdesign-services {
  background: #FFFFFF;
  color: #000000;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.success {
  background: #FFFFFF;
  color: #000000;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  object-fit: contain;
}


.iWebdesign-services h1 {
  color: #000000;
  font-size: 40px;
  text-align: left;
  margin-top: 0rem;
}

.iWebdesign-services h2 {
  color: #000000;
  font-size: 20px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 300;
}

.iWebdesign-services p {
  color: #000000;
  text-align: left;
  font-weight: 200;
  font-size: 14px;
  opacity: 0.8;
}

.iWebdesign-services h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #00000);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}


.iWebdesign-services .card {
  transition: all 0.5s linear;
  border-radius: 16px;
  margin: 1rem;
  margin-top: 2rem;
  padding: 15px;
  box-shadow: 0px 0px #FBE35E;
  border-bottom: 4px solid #FBE35E;
  border-right: 4px solid #FBE35E;
  border-top: 4px solid #FFFFFF;
  border-left: 4px solid #FFFFFF;
}

.iWebdesign-services .card:hover {
  transition: all 0.5s linear;
  border-radius: 16px;
  border-top: 4px solid #4ACAB1;
  border-left: 4px solid #4ACAB1;
  border-bottom: 4px solid #FFFFFF;
  border-right: 4px solid #FFFFFF;
}

.iWebdesign-services .card-title {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0px;
}

.iWebdesign-services .webdesignicons {
  width: 100%;
  height: 100%;
  max-width: 65px;
  min-width: 65px;
  min-height: 60px;
  max-height: 60px;
  text-align: left;
}

.iWebdesign-services .websitedevelopment {
  width: 100%;
  height: 100%;
  max-width: 85px;
  min-width: 85px;
  min-height: 60px;
  max-height: 60px;
  text-align: left;
}

.iWebdesign-services img {
  width: 100%;
  height: auto;
  border-radius: 16px;
  min-height: 450px;
  max-height: 450px;
  object-fit: cover;
  transition: 1s;
}

.iWebdesign-services img:hover {
  border-radius: 16px;
  transition: 1s;
}


.iWebdesign-logo {
  width: 100%;
  height: auto;
  border-radius: 16px;
  min-height: 650px !important;
  max-height: 650px !important;
  object-fit: cover;
  transition: 1s;
}

.iWebdesign-logo:hover {
  border-radius: 16px;
  transition: 1s;
}

.outsourcinglogo {
  width: 100%;
  height: auto;
  border-radius: 16px;
  min-height: 300px !important;
  max-height: 300px !important;
  object-fit: contain;
  transition: 1s;
}

.outsourcinglogo:hover {
  border-radius: 16px;
  transition: 1s;
}

.CompanyLogos {
  display: inline-flex;
  list-style: none;
}

.outsourcingcompanies {
  width: 100%;
  height: auto;
  border-radius: 16px;
  min-height: 150px !important;
  max-height: 150px !important;
  object-fit: contain !important;
  transition: 1s;
  padding:20px;
}

.outsourcingcompanies:hover {
  border-radius: 16px;
  transition: 1s;
}


.customMobileIcon {
  object-fit: contain !important;
  width: 100%;
  height: auto;
  transition: transform .7s ease-in-out;
}
.customMobileIcon:hover
{
  transform: rotate(360deg);
}


@media screen and (max-width: 960px) {
.iWebdesign-services h1 {
    font-size: 30px;
    line-height: normal;
  }
.iWebdesign-services {
    padding: 20px;
    padding-top:0px;
}
.iWebdesign-services .card {
  margin: 0rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

}

@media screen and (max-width: 768px) {
.iWebdesign-services h1 {
    font-size: 30px;
    width: 100%;
    line-height: normal;
  }

.iWebdesign-services p {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }

.outsourcinglogo {
  object-fit: contain !important;
}
.iWebdesign-services img {
  object-fit: contain !important;
}

.parallax {display: none;}
.calltoaction h6 {font-size: 52px !important; margin: 0 auto; width: 98% !important;}

}
