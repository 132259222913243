.intro-container {
  background: #000000;
  color: #FFFFFF;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.intro-container h1 {
  color: #ffffff;
  font-size: 40px;
  text-align: left;
}

.intro-container h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.intro-container p {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
}

.image1 {
  width: 100%;
  height: auto;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}


@media screen and (max-width: 960px) {
  .intro-container {
    padding: 20px;
  }

  .intro-container h1 {
    font-size: 30px;
    line-height: normal;
  }
}

@media screen and (max-width: 768px) {
  .intro-container h1 {
    font-size: 30px;
    margin-top: 0px;
    width: 100%;
    line-height: normal;
  }
  .intro-container p {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
  .image1 {
    width: 100%;
    height: auto;
    margin-top: 5rem;
  }
}
