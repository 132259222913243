.ContactSection {
  background: #FFFFFF;
  color: #000000;
  margin: 0 auto;
  padding: 80px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  object-fit: contain;
}

.ContactSection h1 {
  color: #000000;
  font-size: 40px;
  text-align: left;
}

.ContactSection h2 {
  color: #000000;
  font-size: 20px;
  text-align: left;
  margin-top: 0rem;
  font-weight: 300;
}

.contactDetails h3 {
  font-size:18px;
}

.ContactSection p {
  color: #000000;
  text-align: left;
  font-weight: 200;
  font-size: 14px;
}

.ContactSection h4 {
  color: #ffffff;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #00000);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.contactDetails {
  width: 100%;
  max-width:70%;
  margin: 0 auto;
}

.contactFormBG{
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.contactFormFreeQuote{
  width: 100%;
  max-width:100%;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.freeQuoteSection {
  margin-top: 4rem;
}

.categoryDetails {
    background-color: #000000;
    border-top: 2px solid #4ACAB1;
    border-left: 2px solid #4ACAB1;
    border-radius: 10px;
    color: #FFFFFF;
    padding: 20px;
    text-align: left;
    font-size: 130%;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
}

.star {
  color: #FF3145;
}

input[type="radio"] {
    margin-bottom: 1rem;
    cursor: pointer;
}

.SubmitBtn {
  background-color: #000;
  color: #FFF;
  padding: 8px 20px;
  border: 2px solid #000;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
  float: right;
}
.SubmitBtn:hover
{
  border-top: 2px solid #4ACAB1;
  border-left: 2px solid #4ACAB1;
  color: #4ACAB1;
}

.details {margin-top: 1rem; margin-bottom: 2rem;}

span {
  letter-spacing: normal;
  font-size: 15px;
  margin-bottom: 20px;
}

.form-control:focus {
    border-color: #4ACAB1;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

#serviceType {cursor: pointer;}

.form-control {
    display: block;
    width: 100%;
    max-width: 100%;
    padding-top: 2rem;
    margin: 0 auto;
    border-color: transparent;
    background-color: #f9f9f9;
    background-image: none;
    border-radius: 0px;
    height: 53px;
    padding: 6px 20px;
    font-size: 13px;
    line-height: 24px;
    color: #000;
    border: 1px solid #F1F1F1;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}

@media screen and (max-width: 768px) {
.ContactSection {
  padding: 20px;
}

.SubmitBtn {
  background-color: #000;
  color: #FFF;
  padding: 8px 20px;
  border: 2px solid #000;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
  width: 100%;
  margin-top: 0rem;
}

.contactFormFreeQuote{
  width: 100%;
  max-width:100%;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.contactDetails {
  width: 100%;
  max-width:100%;
  margin: 0 auto;
  padding-top: 2rem;
}

.ContactSection h1 {
    font-size: 30px;
    width: 100%;
    line-height: normal;
  }

.ContactSection p {
    font-size: 14px;
    text-align: left;
    width: 100%;
  }
}
